<template>
  <div>
    <mt-button class="mb15">
      <el-button @click="onAddBtnClick" type="primary">新增</el-button>
      <el-button @click="onUnBindBtnClick" type="primary">解绑</el-button>
    </mt-button>
    <el-table
      ref="userTable"
      :data="userList"
      stripe
      :height="tableHeight"
      :maxHeight="tableHeight"
      @row-click="userRowSelect"
      @selection-change="userRowChange"
      row-class-name="table-row-cursor"
      style="width: 100%;">
      <el-table-column
        header-align="center"
        align="center"
        type="selection"
        width="30">
      </el-table-column>
      <el-table-column
        header-align="left"
        type="index"
        label="#"
        :index="formatterIndex"
        width="50">
      </el-table-column>
      <el-table-column
        header-align="left"
        align="left"
        prop="name"
        label="姓名">
      </el-table-column>
      <el-table-column
        header-align="left"
        align="left"
        prop="loginCode"
        label="账号">
      </el-table-column>
      <el-table-column
        header-align="left"
        align="left"
        prop="roleInfo.name"
        label="角色">
      </el-table-column>
      <el-table-column
        header-align="center"
        align="center"
        label="关联门店">
        <template slot-scope="scope">
          <el-button v-if="scope.row.role === 6" @click.stop="onLookRelationSalesroomBtnClick(scope.row, 'look')" type="text">查看</el-button>
          <div v-else>-</div>
        </template>
      </el-table-column>
      <el-table-column
        header-align="center"
        align="center"
        label="状态">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.wxwechatOpenId" type="success">已绑定</el-tag>
          <el-tag v-else type="info">未绑定</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        header-align="center"
        align="center"
        width="100"
        label="操作">
        <template slot-scope="scope">
          <div v-if="loginUser.role === ROLE.SUPERADMIN || (loginUser.role !== ROLE.SUPERADMIN && scope.row.role !== ROLE.SUPERADMIN)">
            <el-tooltip class="item" effect="dark" content="编辑" placement="top">
              <el-button @click.stop="onEditBtnClick(scope.row)" type="primary" icon="el-icon-edit" circle></el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="删除" placement="top">
              <el-button @click.stop="onDeleteBtnClick(scope.row)" type="danger" icon="el-icon-delete" circle></el-button>
            </el-tooltip>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @current-change="onPageChange"
      layout="->, total, prev, pager, next"
      :page-size="pageSize"
      :current-page="pageIndex"
      :total="userTotal"
      class="pagination">
    </el-pagination>

    <el-dialog class="user-dialog" :title="model.id === 0 ? '新增用户' : '编辑用户'" :visible.sync="dialogVisible" width="50%">
      <el-form :rules="rules" ref="dataForm" :model="model" label-position="left" label-width="100px" class="data-form"
               style='width: 500px; margin:0 auto;'>
        <el-form-item label="名称" prop="name">
          <el-input v-model="model.name"></el-input>
        </el-form-item>
        <el-form-item label="账号" prop="loginCode">
          <el-input v-model="model.loginCode"></el-input>
        </el-form-item>
        <el-form-item label="密码">
          <el-input :disabled="!dialogUserPwdDisabled" v-model="model.password"></el-input>
          <el-checkbox-group v-model="dialogUserPwdDisabled">
            <el-checkbox label="修改（默认密码为:123456）"></el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item required label="角色" prop="role">
          <el-select v-model="model.role" @change="onRoleChange">
            <el-option v-for="item in roleList" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="model.role === 6" label="关联门店" prop="salesroomIds">
          <template v-if="selectedSalesroomList.length !== 0">
            <el-tag v-for="item in selectedSalesroomList" :key="item.id">{{item.name}}</el-tag>
          </template>
          <el-button type="primary" @click="onEditSalesroomBtnClick">编辑</el-button>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="editSupplier">确认</el-button>
      </div>
    </el-dialog>

    <el-dialog class="salesroom-dialog" :title="salesroomOperation === 'look' ? '查看关联门店' : '编辑关联门店'" :visible.sync="showSalesroomModal" width="50%">
      <el-transfer
        v-model="model.salesroomIds"
        :titles="['所有门店', '关联门店']"
        :props="salesroomTransferProps"
        :data="salesroomList"></el-transfer>
      <div slot="footer" class="dialog-footer">
        <template v-if="salesroomOperation === 'look'">
          <el-button @click="showSalesroomModal = false">关闭</el-button>
        </template>
        <template v-else>
          <el-button @click="showSalesroomModal = false">取消</el-button>
          <el-button type="primary" @click="onConfirmSalesroomBtnClick">确认</el-button>
        </template>
      </div>
    </el-dialog>
  </div>
</template>

<script type="text/ecmascript-6">
  import MtButton from '../../components/mt-button'
  import {apiGetUserList, apiEditUser, apiUnBindUser, apiDeleteById} from '../../api/user'
  import {apiGetAllSalesroomList} from '../../api/salesroom'
  import {apiGetRoleList} from '../../api/role'
  import {tableMixin} from '../../mixins/table'
  import {ROLE} from '../../utils/constants'

  export default {
    name: 'SettingUser',
    mixins: [tableMixin],
    components: {MtButton},
    data() {
      let validateIsSelect = (rule, value, callback) => {
        if (value === -1) {
          callback(new Error('请选择'))
        } else {
          callback()
        }
      }
      return {
        userList: [],
        userTotal: 0,
        roleList: [],
        dialogVisible: false,
        dialogUserPwdDisabled: false,
        query: '',
        model: {
          id: 0,
          name: '',
          loginCode: '',
          password: null,
          role: -1,
          salesroomIds: []
        },
        rules: {
          name: [{required: true, message: '不能为空', trigger: 'blur'}],
          loginCode: [{required: true, message: '不能为空', trigger: 'blur'}],
          role: [{validator: validateIsSelect, trigger: 'change'}]
        },
        selectUserList: [],
        ROLE,
        loginUser: {},
        showSalesroomFormItem: false,
        showSalesroomModal: false,
        salesroomOperation: 'look',
        salesroomTransferProps: {
          key: 'id',
          label: 'name'
        },
        salesroomList: [],
        selectedSalesroomList: []
      }
    },
    created() {
      this._getRoleList()
      this._getSalesroomList()
      const userInfo = JSON.parse(sessionStorage.getItem('userInfo'))
      this.loginUser = userInfo
    },
    activated() {
      this._getUserList()
    },
    methods: {
      onUnBindBtnClick() {
        if (this.selectUserList.length === 0) {
          this.$message({
            type: 'warning',
            message: '请选择用户'
          })
          return
        }
        this.$confirm('是否确认将选中用户解绑？', '提示', {
          confirmButtonText: '确认',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          let userIds = []
          for (let i in this.selectUserList) {
            let item = this.selectUserList[i]
            userIds.push(item.id)
          }
          apiUnBindUser(userIds).then(data => {
            this.$refs['userTable'].clearSelection()
            this._getUserList()
            this.$message({
              type: 'success',
              message: '解绑成功'
            })
          })
        })
      },
      // 用户列表行点击事件
      userRowSelect(row, event, column) {
        this.$refs['userTable'].toggleRowSelection(row)
      },
      // 用户多选时的回调
      userRowChange(selection) {
        this.selectUserList = selection
      },
      onAddBtnClick() {
        this.dialogVisible = true
        this._clearModel()
        this._resetModel()
      },
      onEditBtnClick(row) {
        this._clearModel()
        this.dialogVisible = true
        this.dialogUserPwdDisabled = false
        this.model = Object.assign({}, this.model, row)
        this.onConfirmSalesroomBtnClick()
      },
      onEditSalesroomBtnClick() {
        this.onLookRelationSalesroomBtnClick(this.model, 'edit')
      },
      onConfirmSalesroomBtnClick() {
        const selectedSalesroom = this.model.salesroomIds
        let selectedSalesroomList = []
        selectedSalesroom.forEach(item => {
          selectedSalesroomList.push(this.salesroomList.find(v => v.id === item))
        })
        this.selectedSalesroomList = selectedSalesroomList
        this.showSalesroomModal = false
      },
      editSupplier() {
        this.$refs['dataForm'].validate((valid) => {
          if (valid) {
            if (this.model.role === 6 && this.model.salesroomIds.length !== 0) {
              let salesrooms = []
              this.model.salesroomIds.forEach(item => {
                salesrooms.push({
                  id: item
                })
              })
              this.model.salesrooms = salesrooms
            }
            apiEditUser(this.model).then(({data}) => {
              this.pageIndex = 1
              this._getUserList()
              this.dialogVisible = false
              this.$message({
                type: 'success',
                message: this.model.id === 0 ? '添加成功' : '修改成功'
              })
              this._resetModel()
            })
          }
        })
      },
      onDeleteBtnClick(row) {
        this.$confirm('是否确认删除该用户？', '提示', {
          confirmButtonText: '确认',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          apiDeleteById(row.id).then(data => {
            this._getUserList()
            this.$message({
              type: 'success',
              message: '删除成功'
            })
          })
        })
      },
      onLookRelationSalesroomBtnClick(row, operation) {
        // 查看时不能选择
        this.salesroomList.forEach(item => {
          item.disabled = operation === 'look'
        })
        let salesroomIds = []
        if (operation === 'look') {
          this.model = Object.assign({}, this.model, row)
          if (row.salesrooms && row.salesrooms.length !== 0) {
            row.salesrooms.forEach(item => {
              console.log(item)
              salesroomIds.push(item.id)
            })
          }
        } else {
          salesroomIds = row.salesroomIds
        }

        this.model.salesroomIds = salesroomIds
        this.salesroomOperation = operation
        this.showSalesroomModal = true
      },
      onRoleChange(val) {
        // 如果是门店运营
        if (val === 6) {
          this.showSalesroomFormItem = true
        } else {
          this.showSalesroomFormItem = false
        }
      },
      onPageChange(val) {
        this.pageIndex = val
        this._getUserList()
      },
      _clearModel() {
        this.$nextTick(() => {
          this.$refs['dataForm'].clearValidate()
        })
      },
      _resetModel() {
        this.model = {
          id: 0,
          name: '',
          loginCode: '',
          password: null,
          role: -1,
          salesroomIds: []
        }
        this.selectedSalesroomList = []
      },
      _getUserList(query = this.query, pageIndex = this.pageIndex) {
        apiGetUserList(query, pageIndex).then(({data}) => {
          this.userList = data.data
          this.userTotal = data.total
        })
      },
      _getRoleList() {
        apiGetRoleList(1, 99999).then(({data}) => {
          let roleList = data.data
          roleList.unshift({
            id: -1,
            name: '请选择'
          })
          this.roleList = roleList
        })
      },
      _getSalesroomList() {
        apiGetAllSalesroomList().then(({data}) => {
          this.salesroomList = data
        })
      }
    }
  }
</script>

<style lang="stylus" rel="stylesheet/stylus" scoped>
  .salesroom-dialog /deep/ .el-transfer-panel
    text-align left
  .user-dialog /deep/ .el-tag
    margin-right 15px
</style>
